<template>
	<div class="CorpManageIndex">
		<div class="CorpManageIndex1">
			<div class="CorpManageIndex1_1">
				<el-input type="text" prefix-icon="el-icon-search" placeholder="请输入任务名称搜索" v-model="Pages.T_name" clearable
					@keyup.enter.native="searchValue"></el-input>
				<el-button type="primary" icon="el-icon-search" style="margin: 0 10px;" @click="searchValue()">搜索
				</el-button>
			</div>
			<div class="CorpManageIndex1_2" @click="ifyList = true">
				<el-button type="primary" icon="el-icon-plus">添加任务</el-button>
			</div>
		</div>
		<div class="CorpManageIndex2">
			<el-table :data="tableData" style="width: 100%" border>
				<!-- <el-table-column type="index"></el-table-column> -->
				<el-table-column label="任务名称">
					<template slot-scope="scope">
						<i class="el-icon-odometer" style="font-size: 20px;"></i>
						<span style="margin-left: 10px">{{ scope.row.T_name }}</span>
					</template>
				</el-table-column>
				<el-table-column label="公开/隐藏" width="150"> size="small"
					<template slot-scope="scope">
						<el-switch v-model="scope.row.T_State" @change="switchs(scope.row)">
						</el-switch>
					</template>
				</el-table-column>
				<el-table-column label="操作" width="180"> size="small"
					<template slot-scope="scope">
						<el-button type="primary" size="mini" @click="handleEdit(scope.row)">编辑
						</el-button>
						<el-button type="success" size="mini" @click="importFun(scope.row)">导入2.0
						</el-button>
					</template>
				</el-table-column>
				<el-table-column label="更多" width="500">
					<template slot-scope="scope">
						<!-- /dataSources -->
						<el-button type="primary" size="mini" @click="DataSources(scope.row)">数据来源</el-button>
						<!-- /dataEditing -->
						<el-button type="primary" size="mini" @click="DataEditing(scope.row)">数据编辑</el-button>
						<!-- /dataCheck -->
						<el-button type="primary" size="mini" @click="DataCheck(scope.row)">数据校验</el-button>
						<!-- /reportGenerate -->
						<el-button type="primary" size="mini" @click="ReportGenerate(scope.row)">报告生成</el-button>
						<!-- /reportEditing -->
						<el-button type="primary" size="mini" @click="ReportEditing(scope.row)">报告编辑
						</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<div class="CorpManageIndex3">
			<el-pagination background @current-change="handleCurrentChange" :page-size="Pages.page_z"
				layout="total, prev, pager, next, jumper" :total="total">
			</el-pagination>
		</div>
		<!--添加任务-->
		<el-dialog title="添加任务" :visible.sync="ifyList" width="30%" top="0">
			<el-form :model="form" :rules="rules" ref="ruleForm" label-width="80px">
				<el-form-item label="任务名称" prop="T_name">
					<el-input v-model="form.T_name" autocomplete="off" clearable></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="ifyList = false">取 消</el-button>
				<el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
			</div>
		</el-dialog>
		<!--添加任务-->
		<el-dialog title="编辑任务名称" :visible.sync="ifyGongsi" width="30%" top="0">
			<el-form :model="Gongsi" :rules="Gongsirules" ref="ruleForm" label-width="80px">
				<el-form-item label="任务名称" prop="T_name">
					<el-input v-model="Gongsi.T_name" autocomplete="off" clearable></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="ifyGongsi = false">取 消</el-button>
				<el-button type="primary" @click="submitGongsi('ruleForm')">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import {
	TaskList, //任务列表
	TaskAdd, //添加任务
	TaskUp, //显示隐藏
	TaskaddData
} from '../../../../api/CorpManage.js'
export default {
	data() {
		return {
			search: '',
			ifyList: false,//添加任务
			ifyGongsi: false,//编辑公司名称
			value1: null,
			form: {
				T_name: '',
				T_uuid: this.$store.state.CorpData.T_uuid
			},
			rules: {
				T_name: [{
					required: true,
					message: '请输入任务名称',
					trigger: 'blur'
				}]
			},
			Gongsi: {
				T_name: '',
				T_task_id: null
			},
			Gongsirules: {
				T_name: [{
					required: true,
					message: '请输入公司名称',
					trigger: 'blur'
				}]
			},
			Pages: {
				page: 1,
				page_z: 10,
				T_name: null,
				T_uuid: this.$store.state.CorpData.T_uuid
			},
			total: 0, //总条数
			tableData: []
		}
	},
	mounted() {
		this.PostTaskList()
	},
	methods: {
		importFun(e) {
			console.log(e)
			this.$confirm(`此操作将导入【${e.T_name}】, 是否导入?`, '提示', {
				confirmButtonText: '导入立即',
				cancelButtonText: '放弃导入',
				type: 'warning',
				center: true
			}).then(() => {
				TaskaddData({
					T_task_id: e.T_task_id,
					T_name: e.T_name
				}).then(res => {
					console.log('s', res)
					if (res.data.Code == 200) {
						this.$message.success('导入成功');
					}
				})
			}).catch(() => {
				this.$message({
					type: 'info',
					message: '已取消导入'
				});
			});
		},
		handleEdit(e) {//编辑公司名称
			this.Gongsi.T_name = e.T_name
			this.Gongsi.T_task_id = e.T_task_id
			this.ifyGongsi = true//编辑公司名称
		},
		switchs(e) {//switch控制显示隐藏
			console.log('s', e)
			TaskUp({
				T_task_id: e.T_task_id,
				T_State: e.T_State ? 1 : 0
			}).then(res => {
				console.log('s', res)
				if (res.data.Code == 200) {
					this.PostTaskList()
					if (e.T_State) {
						this.$message({
							message: e.T_name + '（显示）设置成功',
							type: 'success'
						});
					} else {
						this.$message({
							message: e.T_name + '（隐藏）设置成功',
							type: 'success'
						});
					}
				}
			})
		},
		submitGongsi(formName) { //提交编辑公司名称
			var _this = this
			_this.$refs[formName].validate((valid) => {
				if (valid) {
					console.log('验证通过')
					TaskUp(_this.Gongsi).then(res => {
						if (res.data.Code == 200) {
							console.log('添加', res)
							_this.$message({
								message: `编辑（${_this.Gongsi.T_name}）成功`,
								type: 'success'
							});
							_this.ifyGongsi = false
							_this.PostTaskList()
						}
					})
				} else {
					console.log('验证未通过');
					return false;
				}
			});
		},
		submitForm(formName) { //提交编辑或者新增按钮
			var _this = this
			_this.$refs[formName].validate((valid) => {
				if (valid) {
					TaskAdd(_this.form).then(res => {
						if (res.data.Code == 200) {
							console.log('添加', res)
							_this.$message({
								message: '任务添加成功',
								type: 'success'
							});
							_this.ifyList = false
							_this.PostTaskList()
						}
					})
				} else {
					console.log('验证未通过');
					return false;
				}
			});
		},
		PostTaskList() {
			var _this = this
			TaskList(this.Pages).then(res => {
				if (res.data.Code == 200) {
					this.tableData = []
					var Arr = res.data.Data.List
					_this.total = res.data.Data.Num
					if (Arr != null) {
						Arr.forEach(function (obj) {
							var objs = obj
							if (objs.T_State == 1) {
								objs.T_State = true
							} else {
								objs.T_State = false
							}
							_this.tableData.push(objs)
						})
					} else {
						console.log('长度为0')
					}
				}
			})
		},
		searchValue() { //搜索
			this.Pages.page = 1
			this.PostTaskList()
		},
		DataSources(e) { //数据来源
			this.$store.commit('T_taskID', e)
			this.$router.push('/dataSources')
		},
		DataEditing(e) {
			this.$store.commit('T_taskID', e)
			this.$router.push('/dataEditing')
		},
		DataCheck(e) {
			this.$store.commit('T_taskID', e)
			this.$router.push('/dataCheck')
		},
		ReportGenerate(e) {
			this.$store.commit('T_taskID', e)
			this.$router.push('/reportGenerate')
		},
		ReportEditing(e) { //报告编辑
			this.$store.commit('T_taskID', e)
			this.$router.push('/reportEditing')
		},
		handleCurrentChange(val) { //当前页
			this.Pages.page = val
			this.PostTaskList()
		},
	}
}
</script>

<style>
@import url("../../../../assets/css/el-dialog.css");

.CorpManageIndex {
	background: #fff;
	padding: 10px;
	overflow-y: scroll;
}

.CorpManageIndex1 {
	display: flex;
	align-items: center;
}

.CorpManageIndex1_1 {
	display: flex;
	align-items: center;
	min-width: 500px;
}


.CorpManageIndex2 {
	margin-top: 20px;
}

.CorpManageIndex3 {
	margin-top: 20px;
}
</style>
